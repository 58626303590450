<template>
  <div class="detailBody" style="height: 100%" v-loading="loadData">
    <div class="bodyHeight">
      <!-- 头部描述信息 -->
      <div class="header">
        <div class="headerLeft">
          <!-- 回退箭头 -->
          <div @click="goBack" class="el-icon-arrow-left backIcon"></div>
          <!--  logo-->
          <div>
            <svg class="icon" aria-hidden="true">
              <use href="#icon-account"></use>
            </svg>
          </div>
          <!-- title -->
          <div class="headerTitle">
            <div class="title">{{ account }}</div>
            <div class="customerName">{{ customerName }}</div>
          </div>
        </div>
        <div class="headerRight">
          <!-- 充值 -->
          <el-button @click="recharge" v-show="linePay">{{
            $t("label.partnerCloud.partnerAccount.topUp")
          }}</el-button>
        </div>
      </div>
      <!-- 中层数据段 -->
      <div class="middleData" v-show="availablerebate || fundList">
        <div
          class="item"
          v-show="index <= 3 && fundList"
          v-for="(item, index) in dataOrign"
          :key="index"
        >
          <!-- title -->
          <div class="itemTitle">{{ item.label }}</div>
          <!-- value -->
          <div class="itemValue">{{ currency }} {{ item.value }}</div>
        </div>
        <div
          v-show="availablerebate && fundList"
          style="border-left: 1px solid #dddbda"
        ></div>
        <div
          v-show="index == 4 && availablerebate"
          class="item"
          style="padding-left: 20px"
          v-for="(item, index) in dataOrign"
          :key="index"
        >
          <!-- title -->
          <div class="itemTitle">{{ item.label }}</div>
          <!-- value -->
          <div class="itemValue">
            {{ currency }} {{ item.value
            }}<span style="margin-left: 10px" v-show="showRevenue"
              >(其中{{ revenueInfo.availablerebate }}于{{
                revenueInfo.validtill
              }}到期)</span
            >
          </div>
        </div>
      </div>
      <!-- 数据列表 -->
      <div class="dataList">
        <!-- title -->
        <div class="dataTitle">
          <div
            style="
              border-bottom: 1px solid #dedcda;
              display: flex;
              width: 100%;
              margin-top: -1px;
            "
          >
            <!-- <div class="dataTitleS">账户流水</div> -->
            <div
              class="dataTitleS"
              @click="choseItem(item)"
              v-for="(item, index) in relationList"
              :key="index"
              v-show="item.show"
              :style="{
                borderBottom:
                  chosedId == item.id ? '2px solid #006DCC' : 'none',
              }"
            >
              {{ item.label }}
            </div>
          </div>
        </div>
        <div class="dataOrign" v-if="chosedId == '001'">
          <!-- 配置区域 -->
          <div class="settingArea">
            <div class="settingAreaLeft">
              <!-- 时间范围 -->
              <div style="margin-right: 10px; font-size: 12px">
                {{ $t("label.mobile.phone.time.range") }}
              </div>
              <!-- 至   开始日期  结束日期 -->
              <el-date-picker
                @change="changeDate"
                @clear="clearDate"
                v-model="date"
                type="daterange"
                value-format="yyyy-MM-dd"
                :range-separator="$t('label.appointment.wizard.subtitle2-2')"
                :start-placeholder="$t('label.chatter.startDate')"
                :end-placeholder="$t('label.chatter.endData')"
              >
              </el-date-picker>
              <!-- 状态 -->
              <div class="settingTitle">
                {{ $t("label.import.index.state") }}
              </div>
              <el-dropdown>
                <el-input
                  readonly
                  class="elInput"
                  v-model="status.codevalue"
                  suffix-icon="el-icon-arrow-down"
                ></el-input>
                <el-dropdown-menu slot="dropdown">
                  <div
                    class="singleObj objTitle"
                    @click="choseObj(item, index)"
                    v-for="(item, index) in statusOrign"
                    :key="index"
                    :style="{
                      background: item.id == status.id ? '#ECF5FF' : '#fff',
                      color: item.id == status.id ? '#7ac2fd' : '#333333',
                    }"
                  >
                    <!--  -->
                    {{ item.codevalue }}
                  </div>
                </el-dropdown-menu>
              </el-dropdown>
              <!-- 操作类型 -->
              <div class="settingTitle">
                {{ $t("label.monitorsys.delete.style") }}
              </div>
              <el-dropdown>
                <el-input
                  readonly
                  class="elInput"
                  v-model="opreateType.codevalue"
                  suffix-icon="el-icon-arrow-down"
                ></el-input>
                <el-dropdown-menu slot="dropdown">
                  <div
                    class="singleObj objTitle"
                    @click="choseType(item, index)"
                    v-for="(item, index) in opreareOrign"
                    :key="index"
                    :style="{
                      background:
                        item.id == opreateType.id ? '#ECF5FF' : '#fff',
                      color: item.id == opreateType.id ? '#7ac2fd' : '#333333',
                    }"
                  >
                    <!--  -->
                    {{ item.codevalue }}
                  </div>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <!-- 按{{sortable}}字段排序,已在{{timeInterval}}刷新 -->
            <div class="settingAreaRight" @click="timeCount">
              <!-- 共{{totalSize}}条数据, -->
              {{
                $t("vue_label_commonobjects_view_sum", { total: totalSize })
              }},
              <!-- 按{{sortable}}字段排序 -->
              {{
                $t("vue_label_commonobjects_view_sortby", { field: sortable })
              }},
              <!-- <span>已在{{timeInterval}}刷新</span>  -->
              {{
                $t("vue_label_commonobject_view_updatedago", {
                  timeInterval: timeInterval,
                })
              }}
              <span
                class="el-icon-refresh-right"
                style="margin-left: 5px"
              ></span>
            </div>
          </div>
          <!-- 数据区域 -->
          <el-table
            :height="firHeight"
            :empty-text="$t('label.weixin.nomore')"
            ref="multipleTable"
            border
            :default-sort="{ prop: 'createdate', order: 'descending' }"
            :data="listsData"
          >
            <el-table-column
              v-for="(item, index) in headList"
              :key="index"
              sortable
              :label="item.label"
              :prop="item.apiname"
            >
              <template slot="header">
                <div style="width: 100%" @click="heaerClick(item)">
                  <span style="margin-right: 10px">{{ item.label }}</span>
                </div>
              </template>
              <template slot-scope="scope">
                <!-- 状态为无效 -->
                <span
                  style="color: #888888"
                  v-if="
                    item.apiname == 'statementamount' &&
                    scope.row['status'] == statusOrign[4].codevalue
                  "
                  >{{ scope.row[item.apiname] }}</span
                >
                <!-- 状态不为无效 -->
                <span v-else>
                  <span
                    style="color: #006dcc"
                    v-if="
                      item.apiname == 'statementamount' &&
                      Number(scope.row['statementamount']) >= 0
                    "
                    >{{ scope.row[item.apiname] }}</span
                  >
                  <span v-else style="color: #080707">{{
                    scope.row[item.apiname]
                  }}</span>
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 返利收入 -->
        <div class="rebateIncome" v-if="chosedId == '002'">
          <el-table
            :data="revenuesListData"
            :height="secHeight"
            border
            :default-sort="{ prop: 'createdate', order: 'descending' }"
            style="width: 100%"
          >
            <el-table-column
              v-for="(item, index) in revenuesData"
              sortable
              :key="index"
              :label="item.label"
              :prop="item.apiname"
            >
              <template slot-scope="scope">
                <span>{{ scope.row[item.apiname] }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 返利支出 -->
        <div class="rebateSpending" v-if="chosedId == '003'">
          <el-table
            :data="expendituresListData"
            :height="secHeight"
            border
            :default-sort="{ prop: 'createdate', order: 'descending' }"
            style="width: 100%"
          >
            <el-table-column
              sortable
              v-for="(item, index) in expendituresData"
              :key="index"
              :label="item.label"
              :prop="item.apiname"
            >
              <template slot-scope="scope">
                <span>{{ scope.row[item.apiname] }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <!-- 分页 -->
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="skippageNum"
        :page-sizes="[10, 25, 50, 100, 200, 500]"
        :page-size="pageSize"
        :layout="layout"
        :total="totalSize"
        :page-count="pageCount"
      >
      </el-pagination>
      <!-- {{$t('vue_label_commonobjects_view_sum',{total:totalSize})}}, -->
      <span style="margin-left: 10px">
        {{
          $t("label.partnerCloud.partnerAccount.thePage", {
            skippageNum: skippageNum,
          })
        }}</span
      >
      <!-- 回到首页 -->
      <span class="go_homepage" @click="goHomePage">{{
        $t("label.partnerCloud.partnerAccount.backHome")
      }}</span>
    </div>
    <recharge-process
      @rechargeCancle="rechargeCancle"
      @paySuccess="paySuccess"
      :rechargeDialog="rechargeDialog"
      v-if="rechargeDialog"
      :payAgain="true"
    ></recharge-process>
  </div>
</template>

<script>
/**
 * 
 */
import rechargeProcess from "@/views/partnerAccount/components/rechargeProcess.vue";
import {
  queryPartnerFund,
  queryRebateRevenue,
  queryRebateExpenditure,
  getPaymentSettings,
  queryRecentlyRevenue,
} from "./api";
import { transformTime } from "@/utils/time.js";
export default {
  components: {
    rechargeProcess,
  },
  props: {},
  data() {
    return {
      //是否显示超能告知按钮
      linePay: false,
      accountId: [],
      account: this.$i18n.t("label.partnerCloud.partnerAccount.account"), //表头账户字段                       //账户
      customerName: this.$i18n.t("label.quickbooks.fieldlabel.company"), //表头客户名称              //客户名称
      timer: null,
      chosedId: null,
      lastRefresh: null,
      totalSize: 0,
      timeInterval: "",
      layout: " sizes, prev, pager,next",
      skippageNum: 1,
      pageSize: 10,
      // pageCount: 50,
      //相关列表选项
      relationList: [
        {
          id: "001",
          label: this.$i18n.t("label.partnerCloud.partnerAccount.accountWater"),
          show: true,
        }, //账户流水
        {
          id: "002",
          label: this.$i18n.t("label.partnerCloud.partnerAccount.rebate"),
          show: true,
        }, //返利收入
        {
          id: "003",
          label: this.$i18n.t("label.partnerCloud.partnerAccount.spending"),
          show: true,
        }, //通过show属性控制后面两个是否显示     //返利支出
      ],
      listsData: [],
      headList: [],
      date: ["", ""],
      opreateType: {
        id: 1,
        codevalue: this.$i18n.t("label.category.1"),
      }, //全部
      status: {
        id: "001",
        codevalue: this.$i18n.t("label.category.1"),
      }, //全部
      dataOrign: [
        {
          label: this.$i18n.t("label.partnerCloud.partnerAccount.available"),
          value: "",
        }, //可用额度
        {
          label: this.$i18n.t(
            "label.partnerCloud.partnerAccount.accountBalance"
          ),
          value: "",
        }, //账户余额
        {
          label: this.$i18n.t("label.partnerCloud.partnerAccount.lineOfCredit"),
          value: "",
        }, //信用额度
        {
          label: this.$i18n.t("label.partnerCloud.partnerAccount.lockLine"),
          value: "",
        }, //锁定额度
        {
          label: this.$i18n.t(
            "label.partnerCloud.partnerAccount.amountOfRebate"
          ),
          value: "",
        }, //可用返利金额
      ],
      opreareOrign: [
        { id: 1, codevalue: this.$i18n.t("label.category.1") }, //全部
      ],
      statusOrign: [
        // 全部、已入账、未入账、冻结、无效
        { id: "001", codevalue: this.$i18n.t("label.category.1") }, //全部
      ],
      rechargeDialog: false, //充值弹框显示与隐藏
      revenuesData: [], //返利收入的表头数据
      revenuesListData: [], //返利收入的列表数据
      expendituresData: [], //返利支出的表头数据
      expendituresListData: [], //返利支出的列表数据
      currency: "",
      sortable: this.$i18n.t("label.trigger.createdate"), //创建时间
      loadData: false,
      // 是否有已启用账户
      fundList: false,
      // 是否启用返利账户
      availablerebate: false,
      firHeight: null,
      secHeight: null,
      showRevenue: false,
      revenueInfo: {},
    };
  },
  watch: {},
  mounted() {
    this.loadData = true;
    this.timeCount();
    this.getPaymentSettings();
    this.getRecentlyRevenue();
    this.chosedId = "001";
  },
  created() {},
  methods: {
    //  查询返利收入可用返利余额临期记录
    async getRecentlyRevenue() {
      let result = await queryRecentlyRevenue();
      if (result.data.availablerebate > 0 && result.data.validtill !== "") {
        this.showRevenue = true;
        let array = result.data.validtill.split("-");
        let str = array[0] + "年" + array[1] + "月" + array[2] + "日";
        this.revenueInfo = result.data;
        this.revenueInfo.validtill = str;
      } else {
        this.showRevenue = false;
      }
      this.reven;
    },
    getHeight() {
      // 高的
      if (this.availablerebate || this.fundList) {
        this.firHeight = "51.2vh";
        this.secHeight = "58vh";
      } else {
        this.firHeight = "61vh";
        this.secHeight = "67.8vh";
      }
    },
    //  支付成功
    paySuccess() {
      this.loadData = true;
      this.timeCount();
      this.getPaymentSettings();
      this.chosedId = "001";
    },
    //  清空日期
    clearDate() {
      this.timeCount();
    },
    //查询支付配置
    async getPaymentSettings() {
      let result = await getPaymentSettings();
      if (result.result == true) {
        if (
          result.data.isPayRecharge == "true" &&
          result.data.isUseWXPay == "true"&&
          this.$store.state.userInfoObj.currencyCode==='CNY'
        ) {
          this.linePay = true;
        }
      }
    },
    //点击回到首页按钮
    goHomePage() {
      this.loadData = true;
      this.skippageNum = 1;
      if (this.chosedId == "001") {
        this.timeCount();
      } else if (this.chosedId == "002") {
        this.queryRebateRevenues();
      } else if (this.chosedId == "003") {
        this.queryRebateExpenditures();
      }
    },
    //  表头排序点击事件
    heaerClick(item) {
      this.sortable = item.label;
    },
    //  获取返利收入
    async queryRebateRevenues() {
      this.revenuesData = [];
      let option = {
        rebateRevenueId: "",
        rebateRevenue: "",
        availableAmount: "",
        exhaustedAmount: "",
        pageNUM: this.skippageNum.toString(),
        pageSize: this.pageSize.toString(),
      };
      let result = await queryRebateRevenue(option);
      this.loadData = false;
      this.revenuesData = result.data.headFieldList;
      let array = result.data.revenueList;
      array.map((item) => {
        item.createdate = transformTime(item.createdate);
        this.revenuesData.map((Sitem) => {
          if (item[Sitem.apiname] == null) {
            item[Sitem.apiname] = "";
          }
        });
        item.revenue = Number(item.revenue);
      });
      // 总条数
      this.totalSize = result.data.count;
      this.revenuesListData = array;
    },
    //  获取返利支出
    async queryRebateExpenditures() {
      this.expendituresData = [];
      let option = {
        pageNUM: this.skippageNum.toString(),
        pageSize: this.pageSize.toString(),
      };
      let result = await queryRebateExpenditure(option);
      this.loadData = false;
      this.expendituresData = result.data.headFieldList;
      let array = result.data.expenditurelist;
      array.map((item) => {
        item.createdate = transformTime(item.createdate);
        this.expendituresData.map((Sitem) => {
          if (item[Sitem.apiname] == null) {
            item[Sitem.apiname] = "";
          }
        });
        item.expenditure = Number(item.expenditure);
      });
      // 总条数
      this.totalSize = result.data.count;
      this.expendituresListData = array;
    },
    changeDate() {
      this.opreareOrign = [
        { id: 1, codevalue: this.$i18n.t("label.category.1") },
      ]; //全部
      this.timeCount();
    },
    //  获取伙伴云流水
    async getStatement() {
      this.listsData = [];
      let string = "";
      let dtringS = "";
      if (
        this.opreateType.codevalue == this.$i18n.t("label.category.1")
      ) {
        //全部
        dtringS = "";
      } else {
        dtringS = this.opreateType.codevalue;
      }
      if (this.status.codevalue == this.$i18n.t("label.category.1")) {
        //全部
        string = "";
      } else {
        string = this.status.codevalue;
      }
      if (!this.date) {
        this.date = ["", ""];
      }
      let obj = {
        startDate: this.date[0],
        endDate: this.date[1],
        status: string,
        operType: dtringS,
        pageNUM: this.skippageNum,
        pageSize: this.pageSize,
      };
      let result = await queryPartnerFund(obj);
      this.loadData = false;
      this.headList = result.data.headFieldList;
      // 设置是否显示返利收入 返利支出
      if (
        result.data.accountInfoList.rebateaccount !== null &&
        result.data.accountInfoList.rebateaccount
      ) {
        this.relationList[1].show = false;
        this.relationList[2].show = false;
      } else {
        this.relationList[1].show = true;
        this.relationList[2].show = true;
      }
      let id = 1;
      this.opreareOrign = [
        { id: 1, codevalue: this.$i18n.t("label.category.1") },
      ]; //全部
      result.data.codelist.map((item) => {
        item.id = ++id;
        this.opreareOrign.push(item);
      });
      this.statusOrign = [
        { id: "001", codevalue: this.$i18n.t("label.category.1") },
      ]; //全部
      let sid = 1;
      result.data.statusCodelist.map((item) => {
        item.id = ++sid;
        this.statusOrign.push(item);
      });
      this.customerName = result.data.accountInfoList[0].name;
      this.accountId = result.data.accountInfoList[0];
      // 可用额度
      this.dataOrign[0].value =
        result.data.fundList.length > 0 &&
        result.data.fundList[0].availablelimit
          ? result.data.fundList[0].availablelimit
          : 0;
      // 账户余额
      this.dataOrign[1].value =
        result.data.fundList.length > 0 &&
        result.data.fundList[0].accountbalance
          ? result.data.fundList[0].accountbalance
          : 0;
      // 信用额度
      this.dataOrign[2].value =
        result.data.fundList.length > 0 && result.data.fundList[0].creditlimit
          ? result.data.fundList[0].creditlimit
          : 0;
      // 锁定额度
      this.dataOrign[3].value =
        result.data.fundList.length > 0 && result.data.fundList[0].locked
          ? result.data.fundList[0].locked
          : 0;
      // 币种
      this.currency = result.data.fundList[0].currency;
      // 可用返利余额
      this.dataOrign[4].value =
        result.data.availablerebateList.length > 0
          ? result.data.availablerebateList[0].avaliable
          : 0;
      // 数据
      let array = result.data.statementList;
      // 解决如果数据为null element表格排序不正确的问题
      array.map((item) => {
        this.headList.map((Sitem) => {
          if (item[Sitem.apiname] == null) {
            item[Sitem.apiname] = "";
          }
        });
        item.createdate = transformTime(item.createdate);
        item.statementamount = Number(item.statementamount);
      });
      // 总条数
      this.totalSize = array.length != 0 ? array[0].totalnum : 0;
      this.listsData = array;
      // 是否有已启用账户
      this.fundList =
        result.data.fundList.length > 0 &&
        result.data.fundList[0].activate == "true"
          ? true
          : false;
      // 是否启用返利账户
      this.availablerebate =
        result.data.availablerebateList.length > 0 &&
        result.data.availablerebateList[0].activate == "true"
          ? true
          : false;
      await this.getHeight();
    },
    //  返回上一级
    goBack() {
      this.$router.go(-1);
    },
    //  切换选项卡
    choseItem(data) {
      this.loadData = true;
      let id = data.id;
      this.chosedId = id;
      this.skippageNum = 1;
      if (id == "002") {
        this.queryRebateRevenues();
      } else if (id == "003") {
        this.queryRebateExpenditures();
      } else if (id == "001") {
        this.timeCount();
      }
    },
    //充值
    recharge() {
      this.rechargeDialog = true;
    },
    //弹框关闭方法
    rechargeCancle() {
      this.rechargeDialog = false;
    },
    //  定义计时方法
    timeCount() {
      this.lastRefresh = new Date();
      // 计时器计算多久前更新
      this.timer = setInterval(() => {
        // 获取当前时间
        let currentTime = new Date();
        let interval = currentTime - this.lastRefresh;

        //计算出相差天数
        var days = Math.floor(interval / (24 * 3600 * 1000));
        //计算出小时数
        var leave1 = interval % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
        var hours = Math.floor(leave1 / (3600 * 1000));
        //计算相差分钟数
        var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
        var minutes = Math.floor(leave2 / (60 * 1000));
        //计算相差秒数
        var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
        var seconds = Math.round(leave3 / 1000);
        hours = minutes > 30 ? hours + 1 : hours;
        if (days > 0) {
          this.timeInterval =
            hours > 0
              ? `${days} ${this.$i18n.t(
                  "label.login.access.day"
                )}${hours} ${this.$i18n.t("label_tabpage_hoursagoz")}`
              : `${days} ${this.$i18n.t("label_tabpage_Sdfez")}`;
        } else if (hours > 0) {
          this.timeInterval = `${hours} ${this.$i18n.t(
            "label_tabpage_hoursagoz"
          )}`;
        } else if (minutes > 0) {
          this.timeInterval = `${minutes} ${this.$i18n.t(
            "label_tabpage_minutesagoz"
          )}`;
        } else {
          this.timeInterval =
            seconds > 43
              ? this.$i18n.t("vue_label_commonobjects_view_secondsbefore", {
                  seconds: seconds,
                })
              : this.$i18n.t("label.notification.time1");
        }
      }, 1000);
      this.pageNUM = "1";
      this.getStatement();
      this.loadData = true;
    },
    // 当前页码发生变化时
    handleCurrentChange(val) {
      this.skippageNum = val;
      this.loadData = true;
      if (this.chosedId == "001") {
        this.timeCount();
      } else if (this.chosedId == "002") {
        this.queryRebateRevenues();
      } else if (this.chosedId == "003") {
        this.queryRebateExpenditures();
      }
    },
    //  分页器切换分页
    handleSizeChange(val) {
      this.loadData = true;
      this.pageSize = val;
      if (this.chosedId == "001") {
        this.timeCount();
        this.getStatement();
      } else if (this.chosedId == "002") {
        this.queryRebateRevenues();
      } else if (this.chosedId == "003") {
        this.queryRebateExpenditures();
      }
    },
    //  下拉菜单切换选项
    choseObj(item) {
      this.loadData = true;
      this.status = item;
      this.timeCount();
      this.getStatement();
    },
    //  下拉菜单切换选项
    choseType(item) {
      this.loadData = true;
      this.opreateType = item;
      this.timeCount();
      this.getStatement();
    },
  },
  computed: {
    pageCount() {
      let { totalSize, pageSize } = this;
      return Math.ceil(totalSize / pageSize);
    },
  },
};
</script>



<style lang="scss" scoped>
::v-deep .cell {
  display: flex !important;
  align-items: center;
}
::v-deep .cell > div {
  width: auto !important;
}
.headerTitle {
  height: 36px;
}
::v-deep .el-date-editor .el-range__close-icon {
  line-height: 24px;
}
.bodyHeight {
  height: calc(100% - 50px);
  overflow: auto;
}
.block {
  background: #fff;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  z-index: 9;
  border-top: 1px solid #dddbda;
}
.detailBody {
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-bottom: 0px;
  height: 100%;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-radius: 2px 2px 3px 3px;
  border: 1px solid #dddbda;
}
.headerLeft {
  display: flex;
  align-items: center;
}
.headerLeft .icon {
  width: 36px;
  height: 36px;
}
.headerLeft > div {
  margin-right: 10px;
}
.title {
  font-size: 12px;
  color: #3e3e3c;
}
.customerName {
  font-size: 16px;
  color: #080707;
  font-weight: 600;
  margin-top: -3px;
}
::v-deep .backIcon {
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.middleData {
  width: 100%;
  padding: 10px 20px;
  border-radius: 2px 2px 3px 3px;
  border: 1px solid #dddbda;
  display: flex;
  margin-top: 10px;
  background: #ffffff;
}
.item {
  padding-right: 40px;
}
.itemTitle {
  font-size: 12px;
  color: #3e3e3c;
  margin-bottom: 15px;
}
.itemValue {
  font-size: 14px;
  color: #080707;
  font-weight: 600;
}

::v-deep .el-button {
  padding: 0px 20px;
  line-height: 30px;
  height: 30px;
  color: #006dcc;
}
.dataList {
  margin-top: 10px;
  border-radius: 2px 2px 3px 3px;
  border: 1px solid #dddbda;
  border-bottom: none;
  // 返利收入
  .rebateIncome {
  }
  // 返利支出
  .rebateSpending {
  }
}
::v-deep .el-table .cell {
  padding-right: 0px;
}
.dataTitle {
  line-height: 40px;
  padding: 10px;
  padding-bottom: 0px;
  padding-right: 0px;
  display: flex;
  background: white;
}
.dataTitleS {
  cursor: pointer;
  padding: 0px 10px;
  font-size: 16px;
  font-weight: 600;
  margin-right: 15px;
}
.settingArea {
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
}
::v-deep .cell a {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
::v-deep .cell > div {
  width: 100% !important;
}
::v-deep .caret-wrapper {
  display: none;
}
.settingAreaLeft {
  display: flex;
  align-items: center;
}
::v-deep .elInput {
  width: 150px;
  cursor: pointer !important;
}
.settingTitle {
  margin: 0px 10px;
  font-size: 12px;
}
.settingAreaRight {
  font-size: 12px;
  color: #888888;
  cursor: pointer;
}
::v-deep .singleObj {
  padding: 7px 44px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.objTitle {
  cursor: pointer;
  font-size: 12px;
}
.objTitle:hover {
  background: #f5f5f5 !important;
  color: #006dcc !important;
}
::v-deep .el-date-editor .el-range-separator {
  width: 7%;
}
::v-deep .el-table th {
  font-weight: 400;
  font-size: 14px;
  font-weight: 600;
  color: #3e3e3c;
  border-right: 1px solid #dedcda;
  padding: 8px 0px;
  background: #fafaf9;
}
::v-deep .el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
  height: 30px;
}
::v-deep .elInput .el-input__inner {
  height: 30px;
  cursor: pointer;
}
::v-deep .el-range-input {
  cursor: pointer;
}
::v-deep .el-date-editor .el-range__icon {
  line-height: 23px;
}
::v-deep .el-date-editor .el-range-separator {
  line-height: 23px;
}
::v-deep .el-input__icon {
  line-height: 30px;
}
::v-deep .el-table td,
.el-table th {
  padding: 10px 0;
}
::v-deep table {
  border-color: #dedcda;
}
::v-deep .el-table__body-wrapper {
  height: 409px;
  overflow-y: auto;
}
.go_homepage {
  margin-left: 20px;
  cursor: pointer;
  color: #006dcc;
}
</style>
